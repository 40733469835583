<template>
    <div class="content">
        <el-button type="primary" class="el-icon-plus" @click="AddClick"> 新增</el-button>

        <el-table :data='DataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
          <el-table-column type="expand">
             <template slot-scope="props">
                <el-row v-for="(item,index) in activeRights(props.row)" :key="item.id" :class="['bdbottom',index===0?'bdtop':'','vcenter']">
                    <el-col :span="5">
                      <el-tag size="medium">{{item.label}}</el-tag>
                      <i class="el-icon-caret-right"></i>
                    </el-col>
                    <el-col :span="19">
                      <el-row v-for="(team,index1) in item.children" :key="team.id" :class="[index1===0?'':'bdtop']">
                        <el-col :span="6">
                          <el-tag type="success" size="medium">{{team.label}}</el-tag>
                          <i class="el-icon-caret-right"></i>
                        </el-col>
                        <el-col :span="18">
                          <el-tag v-for="node in team.children" :key="node.id" type="warning" closable size="medium" @close="RightDel(props.row,node)">{{node.label}}</el-tag>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="gp_name" label="名称"></el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <div class="link_btn">
                        <el-link type="primary" @click="EditClick(scope.row)">编辑</el-link>
                        <el-link type="danger" @click="DelClick(scope.row)">删除</el-link>
                        <el-link type="primary" @click="AddAuth(scope.row)">权限分配</el-link>
                  </div>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="title" :visible.sync="dialogVisible" width='30%' :append-to-body='true'>
            <el-form :model="gpForm" :rules="rules" ref="gpForm" label-width="100px" size='medium'>
                <el-form-item label="名称" prop="gp_name">
                    <el-input v-model="gpForm.gp_name"></el-input>
                </el-form-item>
                <el-form-item label="备注" >
                    <el-input v-model="gpForm.gp_remark" ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :title="title" :visible.sync="authDialogVisible" width='30%' :append-to-body='true'>
            <el-tree :data="RightDataList" show-checkbox :default-expanded-keys="[2]" :accordion='true' node-key="id" ref="tree" highlight-current></el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="authDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="Comfirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {post,get} from '../../util/util'


export default{
    data(){
        return{
            title:'',
            dialogVisible:false,
            gpForm:{
                gp_id:0,
                gp_name:''
            },
            rules:{
                gp_name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
            },

            DataList:[],
            RightDataList:[],
            authDialogVisible:false,


            AuthDataList: []
        }
    },
    computed:{
        activeRights () {
            return function (row) {
                var UserRightsData = []

                var arr = this.AuthDataList.filter(a => a.at_gp_id === row.gp_id)
                if (arr.length > 0) {
                arr.forEach(a => {
                    var strs = a.at_module_id.split('-')
                    const array = UserRightsData.filter(b => b.id === strs[0])
                    if (array.length === 0) { // 一级
                    var label1 = this.GetLabel(a.at_module_id, 1)
                    var form = {
                        id: strs[0],
                        label: label1,
                        children: []
                    }
                    UserRightsData.push(form)

                    // 二级
                    var ID = strs[0] + '-' + strs[1]
                    label1 = this.GetLabel(a.at_module_id, 2)
                    form.children.push({
                        id: ID,
                        label: label1,
                        children: []
                    })

                    // 三级
                    label1 = this.GetLabel(a.at_module_id, 3)
                    form.children[0].children.push({
                        id: a.at_module_id,
                        label: label1
                    })
                    } else { // 二级
                    const ID = strs[0] + '-' + strs[1]
                    var dd = array[0].children.filter(f => f.id === ID)
                    if (dd.length === 0) {
                        const label1 = this.GetLabel(a.at_module_id, 2)
                        dd[0] = {
                        id: ID,
                        label: label1,
                        children: []
                        }
                        array[0].children.push(dd[0])
                    }
                    // 三级
                    const label1 = this.GetLabel(a.at_module_id, 3)
                    dd[0].children.push({
                        id: a.at_module_id,
                        label: label1
                    })
                    }
                })
                }
                return UserRightsData
            }
        },

        GetLabel () {
            return function (id, level) {
                var strs = id.split('-')
                if (level === 1) {
                const arr = this.RightDataList.filter(a => a.id === strs[0])
                if (arr.length > 0) { return arr[0].label }
                }
                if (level === 2) {
                const ID = strs[0] + '-' + strs[1]
                const arr = this.RightDataList.filter(a => a.id === strs[0])
                if (arr.length > 0) {
                    const array = arr[0].children.filter(b => b.id === ID)
                    if (array.length > 0) {
                    return array[0].label
                    }
                }
                }
                if (level === 3) {
                const ID = strs[0] + '-' + strs[1]
                const arr = this.RightDataList.filter(a => a.id === strs[0])
                if (arr.length > 0) {
                    const array = arr[0].children.filter(b => b.id === ID)
                    if (array.length > 0) {
                    var ay = array[0].children.filter(c => c.id === id)
                    if (ay.length > 0) {
                        return ay[0].label
                    }
                    }
                }
                }
            }
        }
    },
    created(){
        this.RightDataList = require('../../util/UserAuth.json')
        
    },
    mounted(){
        this.GetDataList()
    },
    methods:{
        GetDataList () {
            get('group/getgroupdataList').then(res => {
                if (res.rpStatus === 10000) {
                this.DataList = res.list[0]
                this.AuthDataList = res.list[1]
                }
            })
        },
        AddClick(){
            this.dialogVisible=true
            this.title='新增'
            this.gpForm.gp_id=0
            this.gpForm.gp_name=''
        },
        EditClick(row){
            this.dialogVisible=true
            this.title='修改'
            this.gpForm.gp_id=row.gp_id
            this.gpForm.gp_name=row.gp_name
        },
        DelClick(row){
            this.$confirm('确定删除组别“' + row.gp_name + '”？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                get('group/del?id=' + row.gp_id).then(res => {
                    if (res.rpStatus === 10000) { this.$store.dispatch('getGroupDataList') } else { this.$message.error(res.rpMsg) }
                })
            }).catch(e => e)
        },
        submit(){
            this.$refs.gpForm.validate((valid) => {
                if (valid) {
                    post('group/edit', this.gpForm).then(res => {
                        if (res.rpStatus === 10000) {
                            this.$store.dispatch('getGroupDataList')
                            this.dialogVisible = false
                        } else { this.$message.error(res.rpMsg) }
                    })
                }
            })
        },
        
        
        //权限
        AddAuth(row){
            this.authDialogVisible = true
            this.title = '权限分配'
            this.gpId = row.gp_id
            var arr = this.AuthDataList.filter(a => a.auth_gp_id === row.gp_id)
            this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys([])
                if (arr.length > 0) {
                    var data = []
                    arr.forEach(a => {
                    data.push(a.auth_module_id)
                    })
                    this.$refs.tree.setCheckedKeys(data)
                }
            })
        },
        Comfirm(){
            var list = this.$refs.tree.getCheckedKeys()
            if (list.length > 0) {
                var arr = []
                list.forEach(a => {
                if (a.search('-') !== -1) {
                    var strs = a.split('-')
                    if (strs.length === 3) {
                    arr.push({
                        at_gp_id: this.gpId,
                        at_module_id: a
                    })
                    }
                }
                })

                if (arr.length > 0) {
                    post('group/editauth', arr).then(res => {
                        if (res.rpStatus === 10000) {
                        this.authDialogVisible = false
                        for (var index = 0; index < this.AuthDataList.length; index++) {
                            if (this.AuthDataList[index].auth_gp_id === this.gpId) {
                            this.AuthDataList.splice(index, 1)
                            index--
                            }
                        }
                        res.list.forEach(a => {
                            this.AuthDataList.push(a)
                        })
                        }
                    })
                }
            }
        }
    }
}



</script>

<style>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
.el-tag{
  margin:8px;
}

.bdtop{
  border-top: 1px solid #eee;
}

.bdbottom{
  border-bottom: 1px solid #eee;
}

.vcenter{
display: flex;
align-items: center;
}
</style>